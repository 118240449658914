export const MESSAGE_ALL_FRAGMENT = `
  fragment messageAllFragment on Message {
    uid
    user {
        uid
        firstName
        lastName
        banned
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
    group {
        uid
        groupType: type
        users {
            uid
            firstName
            lastName
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
    }
    target {
        __typename
        ... on Deal {
            uid
            name
            title
            exhibitor {
                uid
                name
            }
            displayFileResource {
                schemaCode
                path
            }
        }
        ... on Product {
            uid
            name
            exhibitor {
                uid
                name
            }
            images {
                fullFileResource {
                    schemaCode
                    path
                }
            }
        }
        ... on LargeProduct {
            uid
            name
            exhibitor {
                uid
                name
            }
            images {
                fullFileResource {
                    schemaCode
                    path
                }
            }
        }
    }
    senttime
    senttimestamp
    schemaCode
    type
    content
    userDeleted
  }
`;
